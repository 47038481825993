@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 16px; /* Base font size for mobile */
  }

  @screen sm {
    html {
      font-size: 16px;
    }
  }

  @screen lg {
    html {
      font-size: 18px; /* Slightly larger font size for desktop */
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
  .shadow-text {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-lg {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .tap-highlight-transparent {
    -webkit-tap-highlight-color: transparent;
  }

  @variants responsive {
    .text-balance {
      text-wrap: balance;
    }
  }

  /* Updated responsive utility classes */
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .p-xs {
    padding: 0.25rem;
  }

  .p-sm {
    padding: 0.5rem;
  }

  /* Custom utility for very tight spacing */
  .space-y-1 > * + * {
    margin-top: 0.25rem;
  }

  /* New utility class for very small screens */
  @media (max-width: 390px) {
    .xs\:text-xxs {
      font-size: 0.65rem;
    }
    .xs\:p-xxs {
      padding: 0.25rem;
    }
    .xs\:hidden {
      display: none;
    }
  }
}

@layer components {
  .stat-badge {
    @apply inline-flex items-center px-2 py-1 rounded-full text-xs font-medium;
  }

  .btn {
    @apply py-2 px-4 rounded-lg font-bold cursor-pointer transition duration-200 ease-in-out text-sm;
  }

  .btn-primary {
    @apply bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-700;
  }

  .card {
    @apply bg-white rounded-lg shadow-md overflow-hidden p-4;
  }

  .input {
    @apply w-full px-3 py-2 text-sm text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500;
  }

  .table-responsive {
    @apply w-full overflow-x-auto;
  }
}

/* Custom breakpoint for extra small devices */
@media (max-width: 390px) {
  html {
    font-size: 14px; /* Slightly reduced font size for very small devices */
  }

  .xs\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .xs\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .xs\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
